<template>
  <div :class="$style['layout']">
    <main :class="$style['main']">
      <slot />
    </main>
    <aside :class="$style['aside']">
      <Button :to="{name: 'admin'}">
        Home
      </Button>
      <Button :class="$style['link']" :to="{name: 'admin-products'}">
        Producten
      </Button>
      <Button :class="$style['link']" :to="{name: 'admin-locations'}">
        Locaties
      </Button>
      <!--
      <Button :class="$style['link']" :to="{name: 'admin-themes'}">
        Thema's
      </Button>
      <Button :class="$style['link']" :to="{name: 'admin-colors'}">
        Kleuren
      </Button>
      <Button :class="$style['link']" :to="{name: 'admin-categories'}">
        Categoriën
      </Button> -->

      <Button :to="{name: 'admin-account'}">
        Account
      </Button>
    </aside>
  </div>
</template>
<script setup>
const { user } = useAuth()

</script>
<style module>
.layout {

  display: grid;
  grid-template: "aside main" 1fr "aside main" var(--spacing-xxxxl) / 300px 1fr;
  max-height: 100dvh;
  height: 100dvh;
  width: 100%;
}

@media (width < 70rem) {
  .layout {
    grid-template-areas: "main main" "aside aside";
  }
}

.aside {
  background-color: var(--background-subtle);
  display: flex;
  grid-area: aside;
  flex-direction: column;
  align-items: flex-start;
}

@media (width < 70rem) {
  .aside {
    align-items: center;
    flex-flow: row nowrap;
    overflow-x: scroll;
    overflow-y: auto;
    padding: var(--spacing);
    white-space: nowrap;
  }

  .aside::-webkit-scrollbar {
    display: none;
  }
}

.main {
  background-color: var(--dark-color-5);
  color: var(--text-base);
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
}

.link {
  width: 100%;
}

@media (width < 70rem) {
  .link {
    width: auto;
  }
}
</style>
